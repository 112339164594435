// Currently active tab
var activeTab;

// Timeout for animation delay
var animTimeout;
var messageBar;

// A loading animation
var spinner = '<div class="loader"><svg class="circular"><circle class="path" cx="15" cy="15" r="10" fill="none" stroke-width="2" stroke-miterlimit="10"/></svg></div>';

// Interval for box polling
var timer;
var pollTime = 10000;

// Various elements for global access
var boxContainer = $('#packaging-boxes');
var scanForm = $('#scan-form');
var codeField;
var alertModal = $('#alertModal');
var role_field = $('#role');
var storage_type_field = $('#storage_cell_type_id');
var printButtonId = '#printButton';
var printButton = $(printButtonId);
var ajaxClass = 'ajax-added';
var indicator = $('#loading-indicator');

// Document ready
$(function() {
    $.material.init();
    messageBar = $('#message-container');

    $('body').removeClass('nojs').addClass('js');

    if (scanForm.length) {
        codeField = $('#code');
    }

    // After animation ends, slide the message bar out again after the timeout
    messageBar.on('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function() {
        var self = $(this);
        if (!self.hasClass('slideOutUp')) {
            animTimeout = window.setTimeout(function() {
                self.addClass('slideOutUp');
            }, 3000);
        }
    });;

    // Set the first tab on the page to active
    var tabs = $('a[role=tab]');
    if (tabs.length) {
        var firstTab = tabs.first();
        activeTab = firstTab;
        firstTab.tab('show');
    }

    // Attach datetimepicker
    $('.datetimepicker').datetimepicker({
        format: 'DD.MM.YYYY',
        locale: 'de',
        calendarWeeks: true
    });

    // Start the timer to periodically check for boxes
    if (boxContainer.attr('data-loaded')) {
        startTimer(checkForBoxes, pollTime);
    }

    // Set focus to the print button
    // (or to the scan form field)
    if (printButton.length) {
        printButton.focus();
    } else if (scanForm.length) {
        codeField.focus();
    }

    // Change user form fields depending on the selected role
    if (role_field.length) {
        modifyUserForm();
        role_field.on('change', modifyUserForm);
    }

    // Change storage cell form fields depending on the selected type
    if (storage_type_field.length) {
        modifyStorageCellForm();
        storage_type_field.on('change', modifyStorageCellForm);
    }

});

