// Switch user form field visibility depending on selected role
function modifyUserForm() {
    var packager_fields = $('.packaging-fields');
    var normal_fields = $('.no-packaging');
    var name_field = $('label[for=first_name]');

    if (role_field.val() == 5) {
        normal_fields.hide();
        packager_fields.show();
        name_field.html('Bezeichnung');

    } else {
        normal_fields.show();
        packager_fields.hide();
        name_field.html('Vorname');
    }
}

// Switch storage cell form field visibility depending on selected type
function modifyStorageCellForm() {
    var special_cell_types = [1, 6, 5],
        cat01 = $('.field-category01'),
        cat02 = $('.field-category02');

    if (special_cell_types.indexOf(Number(storage_type_field.val())) > -1) {
        cat01.hide();
        cat02.show();

    } else {
        cat01.show();
        cat02.hide();
    }
}

/**
 * Load a Bootstrap Table datagrid in a tab content container.
 *
 * @param  Event e
 * @return void
 */
function loadDataGrid(e) {
    var hash = e.target.hash,
        dg = $(hash).find('.datagrid-container:not(".loaded")');

    // Set active tab to global scope
    activeTab = $(e.target);

    // Get datagrid markup via AJAX and set the "loaded" class to prevent further reloads
    dg.load(dg.data('url') + ' .datagrid-results', function(data) {
        $('.datagrid-results > table').bootstrapTable({
            onLoadSuccess: function(data) {
                dg.addClass('loaded');
                disableDeleteButtons();
            }
        });

    });
}

function forceRedraw() {
    $(window).trigger('resize');
    lava.redrawCharts();
}

/**
 * Fetches boxes waiting to be processed.
 */
function checkForBoxes() {
    if (boxContainer.length) {
        // Remove previous spinners and alerts
        removeSpinner(boxContainer);
        dismissAlert(boxContainer);

        // Show the loading indicator
        boxContainer.append(spinner);

        $.ajax({
            url: 'boxes'
        })
        .done(function(data) {
            boxContainer.html(data);
        })
        .fail(function() {
            showAlert(boxContainer, 'Fehler bei der Kommunikation mit dem Server: ' + error, 'danger');
        })
        .always(function() {
            removeSpinner(boxContainer);
        });
    }
}

/**
 * Load a designated URL with the handler’s value.
 * @return void
 */
function selectActionHandler() {
    var self = $(this);

    if (self.val()) {
        $(self.attr('data-select-target')).html(spinner).load(self.attr('data-select-action') + self.val());
    }
}

/**
 * Execute an AJAX request and display the results in a modal.
 */
function showConfirmDialog(e) {
    e.preventDefault();
    var self = $(this);

    $.ajax({
        url: self.attr('href')
    })
    .done(function(response) {
        showAlertModal(response.title, response.message, 'danger', response.button);
    });
}

/**
 * Load the response of an AJAX request containing a modal, then show it.
 */
function ajaxModal(e) {
    e.preventDefault();

    var self = $(this)
        body = $('body'),
        modalTarget = self.attr('data-ajax-modal');

    // Hide previously opened modal
    $(modalTarget).modal('hide');

    $.ajax({
        url: self.attr('href'),
    })
    .done(function(response) {
        if ($(modalTarget).length) {
            $(modalTarget).replaceWith(response);

        } else {
            body.append(response);
        }
        $(modalTarget).modal('show');
    })
    .fail(function() {
        showAlertModal('<span class="material-icons md-18 md-btn">warning</span> Fehler', 'Der Server lieferte keine Adressdaten zurück.', 'danger');
    });

}

/**
 * Dispatch an AJAX request to the element’s href
 * @param  {event} e
 */
function ajaxButtonHandler(e) {
    e.preventDefault();
    var self = $(this);

    // Abort if the button is disabled
    if (self.hasClass('btn-disabled')) {
        return;
    }

    var buttonLabel = self.find('.button-text');
    var oldLabel = buttonLabel.html();
    var handler = self.attr('data-handler');
    var targetForm = $(self.attr('data-form-target'));
    var token = self.attr('data-token');
    var method;
    var url;

    self.addClass('btn-disabled');

    // Display a loading indicator
    if (buttonLabel.length) {
        buttonLabel.html(spinner);
    } else {
        self.closest('.modal-footer').append(spinner);
    }

    // Optional data to send along with the request
    var data = {};

    if (targetForm.length) {
        data = targetForm.serialize();
        url = targetForm.attr('action');
        method = targetForm.attr('method');

    } else {
        url = self.attr('href');
        method = self.attr('data-method') ? self.attr('data-method') : 'GET';

        if (method) {
            data._method = method;
        }
        if (token) {
            data._token = token;
        }
    }

    if (!url) {
        throw 'Keine Ziel-URL für die Anfrage übermittelt!';
    }

    $.ajax({
        url: url,
        data: data,
        method: method !== 'GET' ? 'POST' : 'GET'
    })
    .done(function(response, status) {
        buttonLabel.html(oldLabel);

        if (response.error) {
            showAlertModal('<span class="material-icons md-18 md-btn">warning</span> Fehler – Vorgang abgebrochen', response.error, 'danger');

        } else if (response.result) {
            // Dismiss the modal, show a message and refresh an existing datagrid table if no handler has been assigned
            if (!handler) {
                dismissAlertModal();
                showMessageBar('<div class="alert alert-success text-center"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>' + response.result + '</div>');
                $('.datagrid-results .table').bootstrapTable('refresh');
            } else {
                // Attempt to run the handler
                try {
                    handlers[handler](response, self);
                } catch(e) {
                    showAlertModal('<span class="material-icons md-18 md-btn">warning</span> Fehler bei der Verarbeitung der Ergebnisse. ', e.message, 'danger');
                }
            }
        } else {
            showAlertModal('<span class="material-icons md-18 md-btn">warning</span> Fehler – Vorgang konnte nicht beendet werden', 'Der Server lieferte keine Ergebnisse zurück.', 'danger');
        }
    })
    .fail(function(response) {
        buttonLabel.html(oldLabel);
        showAlertModal('<span class="material-icons md-18 md-btn">warning</span> Fehler – Vorgang abgebrochen', 'Die Kommunikation mit dem Server schlug fehl.', 'danger');
    })
    .always(function() {
;       self.removeClass('btn-disabled');
    });
}

/**
 * Submit form data with AJAX and load the returned data in the target element.
 * @param  {event} e
 */
function formSubmitReload(e) {
    e.preventDefault();

    var self = $(this);
    var reloadTarget = $(self.attr('data-submit-reload'));

    // Remove previous spinners and alerts
    removeSpinner(reloadTarget);
    dismissAlert(reloadTarget);

    // Add the loading animation
    reloadTarget.append(spinner);

    $.ajax({
        url: self.attr('action'),
        type: self.attr('method'),
        data: self.serialize(),
        global: true
    })
    .done(function(data) {
        reloadTarget.html(data).attr('data-loaded', 1);
    })
    .fail(function(error) {
        showAlert(reloadTarget, 'Fehler bei der Kommunikation mit dem Server.', 'danger');
    })
    .always(function() {
        removeSpinner(reloadTarget);
    });
}

/**
 * Submit form data with AJAX and pass the result to a handler.
 * @param  {event} e
 */
function formSubmitRequest(e) {
    e.preventDefault();

    var self = $(this);
    var handler = self.attr('data-ajax-handler');
    var container = $(self.attr('data-loading-target'));
    var oldContent = container.html();

    // Display the loading indicator
    indicator.show().addClass('fadeIn').html(spinner);

    // Clear previously loaded AJAX content
    $('.' + ajaxClass).html(null);
    $('.modal').modal('hide');

    $.ajax({
        url: self.attr('action'),
        type: self.attr('method'),
        data: self.serialize(),
        global: true
    })
    .done(function(response) {
        if (response.error) {
            container.html(oldContent);
            showAlertModal('<span class="material-icons md-18 md-btn">warning</span> Fehler – Vorgang abgebrochen', response.error, 'danger');

        } else if (!response.result) {
            container.html(oldContent);
            showAlertModal('<span class="material-icons md-18 md-btn">warning</span> Produkt nicht gefunden', 'Der Artikelcode konnte keiner Bestellung zugeordnet werden.', 'danger');
        } else {
            // Attempt to run the handler
            if (handler) {
                try {
                    handlers[handler](response.result, self, container);
                } catch(e) {
                    showAlertModal('<span class="material-icons md-18 md-btn">warning</span> Fehler bei der Verarbeitung der Ergebnisse. ', e.message, 'danger');
                }
            } else {
                // Simply replace the loading target’s content with the response result
                container.html(response.result);
            }
        }
    })
    .fail(function(response) {
        var message;
        container.html(oldContent);

        if (response.status === 422) {
            message = 'Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es erneut.'
        } else if (response.status === 400) {
            message = 'Ihre Anfrage konnte nicht verarbeitet werden.';
        } else {
            message = 'Unerwarteter Fehler bei der Kommunikation mit dem Server.'
        }

        showAlertModal('<span class="material-icons md-18 md-btn">warning</span> Fehler bei der Anfrage', message, 'danger');
    })
    .always(function() {
        indicator.hide().removeClass('fadeOut').html(null);
    });

}

/**
 * Add the button’s text content to the target element.
 * @param  {event} e
 */
function insertButtonHandler(e) {
    e.preventDefault();

    var self = $(this);
    var parentEl = self.parent();
    var container = $(parentEl.attr('data-button-insert'));
    var targetField = $('[name^="' + parentEl.attr('data-insert-field') + '"]').last();
    var buttonText = self.text();
    var buttonVal = self.val();
    var exists = container.find('.label-' + buttonText);
    var insert = '<div class="label label-default label-' + buttonText + '" title="Entfernen" data-remove-self>' + buttonText + '</div> ';

    if (exists.length) {
        // Remove existing label and remove field to prevent duplicates
        exists.replaceAll(insert);
        $('input[value="' + buttonVal + '"]').remove();
        self.removeClass('btn-raised');

    } else {
        // Insert new hidden field with button value
        var clone = targetField.clone().insertAfter(targetField);
        container.append(insert);
        clone.attr('value', buttonVal);
        self.addClass('btn-raised');
    }
}

/**
 * Toggle the disabled property on the bulk delete button belonging to the datagrid.
 */
function toggleDeleteButton(e) {
    var checkbox = $(e.target),
        btn = checkbox.closest('.tab-pane').find('.delete-selected');

    if (!btn.length) {
        return false;
    }

    var selected = $('#' + btn.attr('data-table')).find('tr.selected');
    btn.prop('disabled', selected.length ? 0 : 1);
}

function disableDeleteButtons() {
    $('.delete-selected').prop('disabled', 1);
}

/**
 * Ask for confirmation before triggering the bulk delete function.
 */
function bulkDeleteConfirm(e) {
    e.preventDefault();
    $('.modal').modal('hide');

    var self = $(this),
        datagrid = $('#' + self.attr('data-table')).find('table'),
        selected = datagrid.find('tr.selected').find('[type="checkbox"]');

    if (!selected.length) {
        self.prop('disabled', 1).blur();
        return false;
    }

    var buttons = '<button type="button" class="btn btn-primary" data-dismiss="modal"><span class="material-icons md-btn md-18">cancel</span>Abbruch</button><button type="button" class="btn btn-danger confirm-delete" data-table="' + self.attr('data-table') + '" data-url="' + self.attr('data-url') + '"><span class="material-icons md-btn md-18">delete</span>Löschen</button>';

    showAlertModal('<span class="material-icons md-18 md-btn">warning</span> Löschen bestätigen', 'Möchten Sie die ausgewählten Datensätze wirklich unwiderruflich löschen?', 'danger', buttons);
}

/**
 * Send bulk delete AJAX request for selected rows in the datagrid associated with the event target.
 */
function bulkDelete(e) {
    e.preventDefault();
    $('.modal').modal('hide');

    var self = $(this),
        datagrid = $('#' + self.attr('data-table')).find('table'),
        selected = datagrid.find('tr.selected').find('[type="checkbox"]');

    if (!selected.length) {
        return false;
    }

    // Get checkbox values (database row ids)
    var values = selected.map(function() {
        return this.value;
    })
    .get()
    .join();

    // Show indicator
    toggleLoadingSpinner();

    // Perform AJAX request
    $.ajax({
        url: self.attr('data-url'),
        type: 'GET',
        data: { ids: values },
    })
    .done(function(response) {
        if (response.error) {
            showAlertModal('<span class="material-icons md-18 md-btn">warning</span> Fehler – Vorgang abgebrochen', response.error, 'danger');

        } else if (!response.result) {
            showAlertModal('<span class="material-icons md-18 md-btn">warning</span> Löschen fehlgeschlagen', 'Unerwarteter Fehler bei der Kommunikation mit dem Server.', 'danger');
        } else {
            showAlertModal('<span class="material-icons md-18 md-btn">check</span> Löschen erfolgreich', 'Die gewählten Datensätze wurden entfernt.', 'success');
        }
    })
    .fail(function(response) {
        var message;

        if (response.status === 422) {
            message = 'Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es erneut.'
        } else if (response.status === 400) {
            message = 'Ihre Anfrage konnte nicht verarbeitet werden.';
        } else {
            message = 'Unerwarteter Fehler bei der Kommunikation mit dem Server.'
        }

        showAlertModal('<span class="material-icons md-18 md-btn">warning</span> Fehler bei der Anfrage', message, 'danger');
    })
    .always(function() {
        // Hide indicator again
        toggleLoadingSpinner();

        // Reload the datagrid
        datagrid.bootstrapTable('refresh');
        disableDeleteButtons();
    });

}

/**
 * Helper to remove the spinner after loading has finished.
 * @return void
 */
function removeSpinner(elem) {
    elem.find('.loader').remove();
}

/**
 * Prepend an alert message to a target element
 * @param  {element} target
 * @param  {string} text
 * @param  {string} type
 */
function showAlert(target, text, type) {
    target.prepend('<div class="alert alert-' + type + '"><button type="button" class="close" data-dismiss="alert" aria-label="schließen"><span aria-hidden="true">&times;</span></button>' + text + '</div>');
}

/**
 * Display messages in a modal
 * @param  {string} message
 * @param  {string} title
 * @param  {string} type
 */
function showAlertModal(title, message, type, buttons) {
    dismissAlertModal();

    if (!buttons) {
        buttons = '<button type="button" class="btn btn-primary" data-dismiss="modal">Schließen</button>';
    }

    alertModal.find('.modal-title').addClass('text-' + type).html(title);
    alertModal.find('.modal-body').html(message);
    alertModal.find('.modal-footer').html(buttons);
    alertModal.modal('show');
}

/**
 * Animate and display the message bar.
 * @param  {string} message
 */
function showMessageBar(message) {
    messageBar.removeClass('slideOutUp').html(message);
}

/**
 * Remove all alert children of the target element.
 * @param  {element} target
 */
function dismissAlert(target) {
    target.find('.alert').remove();
}

/**
 * Close the alert modal.
 */
function dismissAlertModal() {
    alertModal.find('.modal-title').html(null).removeClass('text-danger').removeClass('text-warning').removeClass('text-success').removeClass('text-info');
    alertModal.find('.modal-body').html(null);
    alertModal.find('.modal-footer').html(null);
    alertModal.modal('hide');
}

/**
 * Set an interval to periodically call a function.
 * @param  {function} call
 * @param  {integer} interval
 */
function startTimer(call, interval) {
    timer = window.setInterval(call, interval);
}


function toggleLoadingSpinner() {
    if (indicator.css('display') == 'none') {
        indicator.show().addClass('fadeIn').html(spinner);
    } else {
        indicator.hide().removeClass('fadeOut').html(null);
    }
}
