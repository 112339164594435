// Functions for handling ajax button requests
var handlers = {

    /**
     * Close all open modals on the page after replacing the container HTML with the result.
     *
     * @param  {string} result
     * @param  {object} caller    The calling form
     * @param  {object} container Target container
     */
    replaceAndClose: function (result, caller, container) {
        container.html(result);
        $('.modal').modal('hide');
    },

    /**
     * Handle response after print jobs have been processed.
     *
     * @param  {object} response
     * @param  {object} caller
     */
    afterPrint: function (response, caller) {
        // Display any errors and exit
        if (response.error) {
            showAlertModal('<span class="material-icons md-18 md-btn">warning</span> Fehler', response.error, 'danger');
            return;
        }

        // In case of success, create a clone of the calling element
        var callerClone = caller.clone();
        var oldButton = $('#printButtonOld');

        // Make sure the ID is different
        callerClone.attr('id', 'printButtonOld')
            .removeClass('btn-disabled');

        // Change contents
        callerClone.find('.button-text').html('Neu drucken');
        callerClone.find('.material-icons').html('replay');

        caller.find('.button-text').html('Abschließen');
        caller.find('.material-icons').html('check');
        caller.removeClass('btn-info')
            .removeAttr('data-form-target')
            .attr('href', '/complete/' + response.orderID)
            .attr('data-handler', 'afterFinishing')
            .attr('title', 'Bestellung abschließen')
            .addClass('btn-success')
            .removeClass('btn-disabled')
            .find('.fa-print').removeClass('fa-print').addClass('fa-check-circle');

        // Insert clone after button or replace the old one
        if (oldButton.length) {
            oldButton.replaceWith(callerClone);
        } else {
            caller.after(callerClone);
        }
    },

    /**
     * Redirect after successfully completing the order.
     *
     * @param  {object} response
     */
    afterFinishing: function (response, caller) {
        if (response.error) {
            showAlertModal('<span class="material-icons md-18 md-btn">warning</span> Fehler', response.error, 'danger');
            return;
        }

        caller.remove();

        // Set focus to the scan form
        if (scanForm.length) {
            scanForm.find('.restore-focus').first().val(null).focus();
        }

        // Redirect to designated page
        if (response.redirect) {
            window.location = response.redirect;
        }
    },

    /**
     * Display order information and call the printOrderDocs handler.
     *
     * @param  {integer} orderID
     */
    showOrderDetails: function (orderID, caller, loader) {
        var info = $('#order-info');
        var address = $('#address-info');
        var carriers = $('#carrier-select');

        $.ajax({
            url: '/order-details',
            method: 'GET',
            data: {order_id: orderID}
        })
        .done(function(response) {
            if (response.error) {
                showAlertModal('<span class="material-icons md-18 md-btn">warning</span> Fehler – Vorgang abgebrochen', response.error, 'danger');
            } else {
                // Display response content in their respective containers
                loader.addClass(ajaxClass).html(response.details);
                address.addClass(ajaxClass).html(response.address);
                info.addClass(ajaxClass).html(response.info);
                carriers.addClass(ajaxClass).html(response.carriers);

                // Set focus to the print button
                printButton = $(printButtonId);

                if (printButton.length) {
                    printButton.focus();
                }
            }
        })
        .fail(function(response) {
            showAlertModal('<span class="material-icons md-18 md-btn">warning</span> Fehler bei der Anfrage', 'Die Kommunikation mit dem Server schlug fehl.', 'danger');
        });

    },
};
