// Handle AJAX requests globally after they complete
$(document).ajaxComplete(function(e, xhr, settings) {
    if (settings.url.endsWith('location')) {
        // Send request to check if the scan form can be shown
        $('#scan-form-wrapper').load('/scan-form', function() {
            if (scanForm.length) {
                scanForm.focus();
            }
        });

        // Start periodically checking for boxes when a packaging location has been loaded
        if ($('#boxing_station').val() !== '') {
            startTimer(checkForBoxes, pollTime);
            boxContainer.attr('data-loaded', 1);
        }
    }
});


// Clone parts of a form
$('[data-add-field]').on('click', function(e) {
    e.preventDefault();
    var self = $(this);
    var container = $('#' + self.attr('data-add-field'));
    var last = container.find('.clone-group').last();

    // Create the clone and insert it last in the list of field groups
    var cloned = last.clone();
    cloned.insertAfter(last);
    var fieldClones = cloned.find('input, select, textarea');

    // Remove array index
    fieldClones.each(function(index) {
        var self = $(this);

        if (this.nodeName.toLowerCase() === 'select') {
            self.val(self.find('option:first-child').val());
        } else {
            self.val(null);
        }

        self.attr('name', self.attr('name').replace(/\[\d\]/, '[]'));
    })

    // If there is no remove button, create it
    if (!cloned.find('.input-group').length) {
        // Empty the field
        cloned.wrapInner('<div class="input-group">');
        cloned.find('.input-group').append('<div class="input-group-addon"><button class="btn btn-danger" data-remove-field title="Feld entfernen"><span class="material-icons md-18">remove_circle</span></button></div>');
    }
});

// Remove the clone group closest to this button
$(document).on('click', '[data-remove-field]', function(e) {
    e.preventDefault();
    $(this).closest('.clone-group').remove();
});

// Load a new datagrid when switching tabs
$('a[data-grid-tab]').on('show.bs.tab', loadDataGrid);

// Redraw charts when switching tabs
$('a[data-chart-tab]').on('show.bs.tab', forceRedraw);

// Trigger an AJAX reload request on form submit
$('form[data-submit-reload]').on('submit', formSubmitReload);

// Fire AJAX action on form field change
$('[data-select-action]').on('change', selectActionHandler);

// AJAX requests for buttons with loading animation
$(document).on('click', '[data-ajax-button]', ajaxButtonHandler);

// Send an AJAX request and show a confirmation modal on success
$(document).on('click', '[data-ajax-confirm]', showConfirmDialog);

// Submit a form with AJAX
$(document).on('submit', '[data-ajax-form]', formSubmitRequest);

// Display a modal with AJAX content
$(document).on('click', '[data-ajax-modal]', ajaxModal);

// Bulk deletion request
$(document).on('click', '.delete-selected', bulkDeleteConfirm);
$(document).on('click', '.confirm-delete', bulkDelete);

// Toggle delete button availability on datagrid checkboxes
$(document).on('change', '.bootstrap-table .bs-checkbox input', toggleDeleteButton);

$('.datagrid-results table').bootstrapTable({
    onLoadSuccess: disableDeleteButtons,
});

// Add text to element on button click
$('[data-button-insert]').find('.btn').on('click', insertButtonHandler);
